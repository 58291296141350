import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Lançamentos from 'views/Lançamentos';
import LançamentosGerar from 'views/Lançamentos/Gerar';
import LançamentosDetalhes from 'views/Lançamentos/Detalhes';
//RELATÓRIOS
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Lancamentos" element={<Outlet />}>
      <Route path="" element={<Lançamentos />} />
      <Route path="Detalhes/:hash" element={<LançamentosDetalhes />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="" element={<LançamentosGerar />} />
        <Route path=":hash" element={<LançamentosGerar />} />
      </Route>
    </Route>
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
  </Routes>
);

export default RoutesTree;
