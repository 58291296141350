import { Check, Close } from '@mui/icons-material';
import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment';

const StatusCell = ({ value }) => {
  const props = {
    SIM: { label: 'Contabilizado', icon: <Check />, color: 'success' },
    NAO: { label: 'Não Contabilizado', icon: <Close />, color: 'error' },
  };

  return (
    <Chip sx={{ flex: 1 }} size="small" variant="outlined" {...props[value]} />
  );
};

const LancamentoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Nº do Documento',
      value: row?.documento,
    },
    {
      label: 'Valor',
      value: row?.valor?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Data de Lançamento',
      value: row?.dtlanc && moment(row?.dtlanc).format('DD/MM/YYYY'),
    },
  ];

  let str = '';
  const hasUser = Boolean(row?.usuario);
  const hasDh = Boolean(row?.datahora) && moment(row?.datahora).isValid();
  if (hasUser && hasDh) {
    str = `Lançado por ${row?.usuario} em ${moment(row?.datahora).format(
      'DD/MM/YYYY [às] HH:mm'
    )}`;
  } else if (hasUser) {
    str = `Lançado por ${row?.usuario}`;
  } else if (hasDh) {
    str = `Lançado em ${moment(row?.datahora).format('DD/MM/YYYY [às] HH:mm')}`;
  }

  return (
    <Box flex={1} display="flex" flexDirection="column">
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
      {Boolean(str) && (
        <Typography variant="caption" textAlign="end">
          {str}
        </Typography>
      )}
    </Box>
  );
};

const ContasCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Conta de Crédito',
      value: row?.ctacred,
    },
    {
      label: 'Conta de Débito',
      value: row?.ctadeb,
    },
    {
      label: 'Regra Utilizada',
      value: row?.regra,
    },
  ];
  return (
    <Box flex={1}>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const mock = [
  {
    field: 'contabilizado',
    renderCell: (props) => <StatusCell {...props} />,
  },
  {
    field: 'lancamento',
    renderCell: (props) => <LancamentoCell {...props} />,
  },
  {
    field: 'contas',
    renderCell: (props) => <ContasCell {...props} />,
  },
];

export default mock;
