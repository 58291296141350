import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropRegraIntegracao } from 'utils/drops';
import { LançamentosContext } from 'contexts/LançamentosContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const ContabilizarModal = ({ onSubmit }) => {
  const defaultValues = { types: [] };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(LançamentosContext);

  return (
    <>
      <DialogTitle>Contabilizar Lançamentos</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="body2">
              Selecione os tipos de lançamentos que deseja contabilizar
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="types"
              control={control}
              label="Tipos"
              options={dropRegraIntegracao}
              multiple
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Confirmar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ContabilizarModal;
