import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { decryptURL } from 'utils/functions';
import { LançamentosContext } from 'contexts/LançamentosContext';
import TableContainer from 'components/TableContainer';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';
import moment from 'moment';

const Detalhes = () => {
  const { hash = '' } = useParams();
  const { getDetalhes, getLoading } = useContext(LançamentosContext);
  const [doc, setDoc] = useState(null);
  const titulo = 'Detalhes do Lançamento';
  const decrypt = decryptURL(hash);
  const linesDoc = [
    {
      value: `${doc?.documento?.especie || ''} • ${
        doc?.documento?.natureza || ''
      } • ${doc?.documento?.grupo || ''}`,
      label: 'Espécie • Natureza • Grupo',
    },
    { value: doc?.documento?.documento || '-', label: 'Nº do Documento' },
    {
      value:
        Boolean(doc?.documento?.dtemissao) &&
        moment(doc?.documento?.dtemissao)?.format('DD/MM/YYYY [às] HH:mm'),
      label: 'Data de Emissão',
    },
    {
      value: (doc?.documento?.vldocto || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      label: 'Valor',
    },
    { value: doc?.documento?.filial, label: 'Filial' },
    { value: doc?.documento?.cadastro, label: 'Entidade' },
  ];
  const financeiroHeader = [
    { key: 'parcela', label: 'Parcela' },
    { key: 'dtvenc', label: 'Data de Vencimento' },
    { key: 'valor', label: 'Valor', align: 'right' },
  ];
  const tributoHeader = [
    { key: 'tributo', label: 'Tributo' },
    { key: 'valor', label: 'Valor', align: 'right' },
  ];
  const propsDoc = {
    sm: 4,
    xs: 12,
    item: true,
    display: Boolean(doc?.documento) ? 'flex' : 'none',
  };
  const propsTransf = {};
  const propsAvulso = {};

  useEffect(() => {
    const params = {
      documento_id: decrypt?.documento_id,
      financeiro_movimento_id: decrypt?.financeiro_movimento_id,
      cxlanctos_id: decrypt?.cxlanctos_id,
    };
    getDetalhes({ params, cb: setDoc });
  }, []);

  const Line = ({ value = '', label = '', first = false }) => (
    <>
      {Boolean(first) && <Divider />}
      <Box display="flex" my={0.5}>
        <Typography flex={1} variant="body2" fontWeight="bold">
          {label}:
        </Typography>
        <Typography flex={1} variant="body2">
          {value}
        </Typography>
      </Box>
      <Divider />
    </>
  );

  const CustomTable = ({ headers = [], rows = [] }) => {
    const formatValue = ({ row, key }) => {
      if (key === 'valor') {
        return (row[key] || 0)?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      }
      if (key === 'dtvenc') {
        if (Boolean(row[key])) {
          return moment(row[key])?.format('DD/MM/YYYY');
        }
        return '';
      }
      return row[key];
    };
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers?.map(({ label = '', ...rest }) => (
                <TableCell {...rest}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow key={i?.toString()}>
                {headers?.map(({ key, ...rest }) => (
                  <TableCell {...rest} key={key}>
                    {formatValue({ row, key })}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid {...propsDoc}>
          <Card title="Documento" style={styles?.card}>
            {linesDoc?.map((props, i) => (
              <Line {...props} first={i === 0} />
            ))}
          </Card>
        </Grid>
        <Grid {...propsDoc}>
          <Card title="Financeiro" style={styles?.card}>
            <CustomTable
              headers={financeiroHeader}
              rows={doc?.financeiros || []}
            />
          </Card>
        </Grid>
        <Grid {...propsDoc}>
          <Card title="Tributos" style={styles?.card}>
            <CustomTable headers={tributoHeader} rows={doc?.impostos || []} />
          </Card>
        </Grid>
        <Grid item xs={12} display="flex">
          <Card title="Contabilização" style={styles?.card}>
            <DataGrid
              rows={doc?.lancamentos || []}
              columns={[
                {
                  field: 'dtlanc',
                  headerName: 'Data de Lançamento',
                  width: 200,
                  sortable: false,
                  valueFormatter: ({ value }) =>
                    Boolean(value) && moment(value)?.format('DD/MM/YYYY'),
                },
                {
                  field: 'valor',
                  headerName: 'Valor',
                  type: 'number',
                  width: 200,
                  sortable: false,
                  valueFormatter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'ctacred',
                  headerName: 'Conta de Crédito',
                  width: 450,
                  sortable: false,
                },
                {
                  field: 'ctadeb',
                  headerName: 'Conta de Débito',
                  width: 450,
                  sortable: false,
                },
                {
                  field: 'historico',
                  headerName: 'Histórico',
                  width: 450,
                  sortable: false,
                },
              ]}
              autoHeight
              pagination
              pageSize={10}
              density="compact"
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              disableSelectionOnClick
              localeText={localeText}
              keepNonExistentRowsSelected
              getRowHeight={() => 'auto'}
              components={{ Pagination: CustomPagination }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Detalhes;
