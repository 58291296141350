import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { LançamentosProvider } from 'contexts/LançamentosContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <RelatoriosProvider>
            <LançamentosProvider>
              <ModalProvider>
                <DialogProvider>
                  <DrawerProvider>{children}</DrawerProvider>
                </DialogProvider>
              </ModalProvider>
            </LançamentosProvider>
          </RelatoriosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
