export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropRegraIntegracao = [
  { value: 'BAIXA', label: 'BAIXA' },
  { value: 'CAIXA E BANCOS', label: 'CAIXA E BANCOS' },
  { value: 'COMERCIALIZACAO', label: 'COMERCIALIZAÇÃO' },
  { value: 'ESTOQUE', label: 'ESTOQUE' },
  { value: 'FINANCEIRO', label: 'FINANCEIRO' },
  { value: 'PESSOAL', label: 'PESSOAL' },
  { value: 'TRIBUTACAO', label: 'TRIBUTAÇÃO' },
];
