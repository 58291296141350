import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  contabilizado: null,
  documento: '',
  valor: '',
  dtlanc: {
    i: null,
    f: null,
  },
  usuario: [],
  datahora: {
    i: null,
    f: null,
  },
  ctacred: [],
  ctadeb: [],
  regra: '',
  historico: '',
};

export const LançamentosContext = createContext();

export const LançamentosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [lançamentos, setLançamentos] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtlanc', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getLançamentos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: lançamentos?.filter,
        page: lançamentos?.page,
        size: lançamentos?.size,
        order: lançamentos?.order,
        ...payload,
      });
      setLançamentos((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postContabiliza = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Contabil/Lancamentos/Contabiliza', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getDetalhes = async ({ params, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Contabil/Lancamentos`, { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <LançamentosContext.Provider
      value={{
        getLoading,
        lançamentos,
        postLoading,
        getDetalhes,
        getLançamentos,
        postContabiliza,
      }}
    >
      {children}
    </LançamentosContext.Provider>
  );
};
